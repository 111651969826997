<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <van-dropdown-menu active-color="#23AB72">
      <van-dropdown-item v-model="query.schoolYear" :options="options" @change="changeYear" />
    </van-dropdown-menu>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad" style="margin-bottom:64px">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.schoolYear +'年'}}</span></span>
          </template>
          <template #label>
            <div class="custom-title margin6 hidden-text95">
              {{ row.stuName +"  " +row.stuNo }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="clock-o" /> 处分时间: {{parseTime(row.disciplinaryAt, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="newspaper-o" /> 处分结果: {{codeConvertLabel(row.punishResult,dict.disciplinary_punish_res)}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="notes-o" /> 处分期限: {{ codeConvertLabel(row.punishAt,dict.disciplinary_punish_date)}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.isRevoke" class="audit success-color b-color-success">已撤销</span>
            <span v-else class="audit error-color b-color-error">未撤销</span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <van-button class="table-edit-btn" :disabled="row.isRevoke" style="width:80px" @click="doCancel(row)" plain round size="small" type="primary">撤销违纪</van-button>
          </template>
          <template #right-icon>
            <span class="table-detail-btn" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  name: "StuPayReport",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj", "disciplinary_punish_res", "disciplinary_punish_date"],
  data() {
    return {
      options: [],
      loading: false
    }
  },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  created() {

  },
  methods: {
    beforeInit() {
      this.url = "/api/stuDisciplinaryManage";
      this.sort = ['createTime,desc', 'schoolYear,desc'];
      this.query.approvalStatus = [9]
      this.query.schoolYear = new Date().getFullYear().toString();
      return true;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.schoolYear = val;
      });
      this.toQuery()
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.yearNo = new Date().getFullYear().toString();
      }
      return options;
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/disciplinary/detail",
        query: { id: data.id, status: data.cancelApprovalStatus },
      });
    },
    doCancel(data) {
      this.$router.push({
        path: "/xs/disciplinary/cancel/form",
        query: { id: data.id, },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
</style>